import React, { Component } from 'react';

import Gallery from '../Helpers/Gallery';
import GalleryDescription from '../Helpers/GalleryDescription'

import '../../styles/GalleryPage.css';

import { artwork } from '../../constants/displayedItems';


export default class ArtGallery extends Component {



    render = () => {

        return (
        
            <div className='galleryPage fadeQuick'> 

                <div style={{height: 40}}/>

                {/* Description */}
                <GalleryDescription
                    unpadTop = {true}
                >

                    <p>
                        Artwork is original.
                    </p>

                    <p className='indentText'>
                        - If you have any questions regarding artwork,  or are interested in purchasing please email  noelle@nfdesignstudio.com
                    </p>

                </GalleryDescription>

                {/* Art Gallery */}
                <Gallery
                    items = { artwork }
                    animate = {true}
                />

                <div style={{height: 40}}/>
                

            </div>

            


        )
        
    }


}