

export const qualifications = [
    {
        src: '/resources/Icons/ASID.png',
        invert: true,
    },
    // {
    //     src: '/resources/Icons/leedGreen.png'
    // }

]

export const artwork = [

    {
        src: '/resources/Art/grief.png',
        title: 'Grief'
    },
    {
        src: '/resources/Art/guardian.png',
        title: 'Guardian'
    },
    {
        src: '/resources/Art/guardianDetail.png',
        title: 'Guardian (Detail)'
    },
    {
        src: '/resources/Art/here3.jpg',
        title: 'Here (3)'
    },
    {
        src: '/resources/Art/pain.jpg',
        title: 'Pain'
    },
    {
        src: '/resources/Art/sorrow1.png',
        title: 'Sorrow (1)'
    },
    {
        src: '/resources/Art/sorrow2.png',
        title: 'Sorrow (2)'
    },
    {
        src: '/resources/Art/tribe1.jpg',
        title: 'Tribe (1)'
    },
    {
        src: '/resources/Art/tribe2.jpg',
        title: 'Tribe (2)'
    },
    {
        src: '/resources/Art/daughter.jpg',
        title: 'Daughter'
    },
    {
        src: '/resources/Art/crossCountry.jpg',
        title: 'Cross Country'
    },
    


]


export const photography = [

    {
        src: '/resources/Photo/phot1.jpg',
        title: 'NYC Vault'
    },
    {
        src: '/resources/Photo/phot2.jpg',
        title: 'NYC'
    },
    {
        src: '/resources/Photo/phot4.jpg',
        title: 'NYC Courtyard'
    },
    {
        src: '/resources/Photo/phot5.jpg',
        title: 'Brooklyn Graffiti'
    },
    {
        src: '/resources/Photo/phot6.jpg',
        title: 'Freedom!'
    },
    {
        src: '/resources/Photo/phot7.jpg',
        title: 'NYC Copper Cornice'
    },
    {
        src: '/resources/Photo/phot8.jpg',
        title: 'Brooklyn Vault'
    },
    {
        src: '/resources/Photo/phot9.JPG',
        title: "Hell's Kitchen Vault"
    },
    {
        src: '/resources/Photo/phot10.JPG',
        title: 'Ghost in the Vault'
    },
    {
        src: '/resources/Photo/photo11.jpg',
        title: 'Terra Cotta Detail'
    },
    {
        src: '/resources/Photo/photo13.jpg',
        title: "Sweet 16 (1)"
    },
    {
        src: '/resources/Photo/photo14.jpg',
        title: "Sweet 16 (2)"
    },
    {
        src: '/resources/Photo/photo15.jpg',
        title: 'NYC Terra Cotta'
    },
    {
        src: '/resources/Photo/photo16.jpg',
        title: "St. Patrick's NYC"
    },
    {
        src: '/resources/Photo/wedding1.jpg',
        title: "Wedding"
    },
    {
        src: '/resources/Photo/wedding2.jpg',
        title: "Wedding (2)"
    },
    {
        src: '/resources/Photo/gobletOfFire.jpg',
        title: "Goblet of Fire"
    },
    {
        src: '/resources/Photo/theShining.jpg',
        title: "The Shining"
    },
    {
        src: '/resources/Photo/rennaissancePgh.jpg',
        title: "Renaissance Pittsburgh"
    },
    {
        src: '/resources/Photo/mannequins.jpg',
        title: "Mannnequins"
    },
    {
        src: '/resources/Photo/bedfordOak.jpg',
        title: "Bedford Oak"
    },

    
]



export const architecture = [
    {
        src: '/resources/Cad/archOne.jpg',
    },
    {
        src: '/resources/Cad/archTwo.jpg',
    },
    {
        src: '/resources/Cad/archThree.jpg',
    },
    {
        src: '/resources/Cad/homeRender1.jpg'
    },
    {
        src: '/resources/Cad/homeRender2.jpg'
    },
]


export const structuralEngineering = [
    
    {
        src: '/resources/Cad/CADthree.jpg',
        // info: ['Structural Info'],
    },
    {
        src: '/resources/Cad/CADtwo.jpg',
        // info: ['Structural Info'],
    },
    {
        src: '/resources/Cad/structOne.jpg',
        // info: ['Structural Info'],
    },
    {
        src: '/resources/Cad/structTwo.jpg',
        // info: ['Structural Info'],
    },
    {
        src: '/resources/Cad/CADhome.jpg',
        // info: ['Structural Info'],
    },
    {
        src: '/resources/Cad/CADone.jpg',
        // info: ['Structural Info'],
    },


]

export const interiorDesign = [
    {
        src: '/resources/IntDes/intDes1.jpg',
        info: ['A custom designed and created slate / glass mosaic backsplash add an organic feel to a new kitchen.']        
    },
    {
        src: '/resources/IntDes/intDes2.jpg',
        // info: ['Interior Design Info']        
    },
    {
        src: '/resources/IntDes/intDes3.jpg',
        // info: ['Interior Design Info']        
    },
    {
        src: '/resources/IntDes/intDes4.jpg',
        // info: ['Interior Design Info']        
    },
    {
        src: '/resources/IntDes/IntDesHome.jpg',
        // info: ['Interior Design Info']        
    },
]