import React, { Component } from 'react';

import '../styles/SlideShow.css';


class SlideShow extends Component {


    state = {

        displayIdx: 0,

    }



    render = () => {

        return (

            <div className="slideShowContainer">

                <img className='slideShowImg' src={this.props.slides[this.state.displayIdx].src} />


            </div>

        )
    }

}

export default SlideShow;