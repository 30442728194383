import React, { Component } from 'react';

import InfoCard from './InfoCard';

import '../../styles/Gallery.css';

export default class GalleryDescription extends Component {



    render = () => {

        return (
        
            <div className={'galleryDescription' + (this.props.unpadTop ? ' unpadTop' : '')}> 


                {this.props.title &&
                    <h1> { this.props.title }</h1>
                }


                {this.props.children}


            </div>

            


        )
        
    }


}