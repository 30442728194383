import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';

import "../styles/Footer.css"


const links = [

    {
        display: 'Art Gallery',
         link: '/Artwork'
    },
    {
        display: 'Professional Work Gallery',
         link: '/Work'
    },

]


const iconLinks = [
    {
        src: '/resources/Icons/facebook.png',
        invert: false,
        link: 'https://www.facebook.com/noellefontainedesignstudio/'
    },
    {
        src: '/resources/Icons/linkedin.png',
        invert: false,
        link: 'https://www.linkedin.com/in/noellefontaine'
    },
    {
        src: '/resources/Icons/flickr.png',
        invert: false,
        link: 'https://www.flickr.com/sisternoey/'
    },
    {
        src: '/resources/Icons/gmail.png',
        invert: false,
        link: 'mailto:noelle@nfdesignstudio.com'
    }
]


class Footer extends Component {


    state = {

    }







    render = () => {

        return (
            <div className="Footer">

                {/* TODO: Footer Contact */}
                <div className='contactInfo'>
                    Contact: noelle@nfdesignstudio.com
                </div>

                {/* TODO: Footer Icons */}
                {iconLinks.map(linkInfo => {

                    return (
                        <a href = {linkInfo.link} target='_blank'>
                        <img className={"footerIcon" + (linkInfo.invert ? ' invert' : '')} 
                             src={linkInfo.src} 
                        />
                        </a>
                    )
                        
                })}
                

                
                
            </div>

        )
    }

}


export default withRouter(Footer);