import React, { Component } from 'react';

import Gallery from '../Helpers/Gallery';
import GalleryDescription from '../Helpers/GalleryDescription'

import '../../styles/GalleryPage.css';

import { photography } from '../../constants/displayedItems';


export default class PhotoGallery extends Component {



    render = () => {

        return (
        
            <div className='galleryPage fadeQuick'> 

                <div style={{height: 40}}/>

                {/* Description */}
                <GalleryDescription
                    unpadTop = {true}
                >

                    <p>
                        If you are interested in purchasing prints, or in a photography sitting, please email noelle@nfdesignstudio.com
                    </p>

                </GalleryDescription>


                {/* Photography Gallery */}
                <Gallery
                    items = { photography }
                    animate = {true}
                />
                
                <div style={{height: 40}}/>

            </div>

            


        )
        
    }


}