import React, { Component } from 'react';

import Gallery from '../Helpers/Gallery';
import GalleryDescription from '../Helpers/GalleryDescription';

import '../../styles/GalleryPage.css';

import { structuralEngineering, architecture } from '../../constants/displayedItems';


export default class CADGallery extends Component {



    render = () => {

        return (
        
            <div className='galleryPage fadeQuick'> 

                <div style = {{height: 40}}/>


                {/* Description */}

                <GalleryDescription>

                    <p>
                        CAD drawings of existing building structures, both interior and exterior.
                    </p>

                    <p className='indentText'>
                        - Surveys are completed using a combination of hand and laser measuring, photography, and satellite imagery.
                    </p>

                    <p className='indentText'>
                        - Accurate drawings are produced using AutoCAD, Revit, or Vectorworks.
                    </p>

                </GalleryDescription>


                {/* Structural Gallery */}
                <Gallery
                    items = { structuralEngineering }
                    animate = {true}
                />


                {/* Architecture Gallery */}
                <Gallery
                    items = { architecture }
                    animate = {true}
                />
                

            </div>

            


        )
        
    }


}