import React, { Component } from 'react';

import '../styles/homePage.css';
import SlideShow from './Slideshow';

import { qualifications } from '../constants/displayedItems'


class HomePage extends Component {

    state = {
    }

    componentDidMount = () => {

    }



    render = () => {

        return (

            <div className="homePage fadeQuick">

                <div className='headerImage'>
                    <SlideShow 
                        slides = {[
                            {src: '/resources/HomeSlides/Slide4.jpg'},
                        ]}
                    />


                    {/* Namae Info */}
                    <div className='headerTitleBlock'>
                        {/* <div className='headerTitle'>
                            
                        </div> */}

                        <div className='headerTitle headerTitle2'>
                            Surveying, Drafting, and Design
                        </div>
                    </div>
                    

                    {/* Qualifications */}
                    <div className='qualificationRow'>
                        {qualifications.map(qualInfo => {
                            return <img className={qualInfo.invert ? 'invert' : ''}  src={qualInfo.src}/>
                        })}
                        
                    </div>
                </div>


                <div className='headerBio'>

                        <p>
                            Located in New York's Hudson Valley,  Noelle Fontaine Design Studio, Ltd. serves clients in Westchester County and New York City.
                        </p>

                        <p>
                            A graduate of The Fashion Institute of Technology, Noelle holds a degree in Interior Design, is a Professional Member of ASID with her NCIDQ certification, and is a LEED Green Associate.
                        </p>

                        <p>
                            Throughout her career, Noelle has been employed in the fields of interior design, architecture and engineering and enjoys both residential and commercial design.
                        </p>

                        <p>
                            Noelle enjoys seeing Manhattan from rooftops and vaults and finds that surveying historic buildings and ‘fitting together the pieces of the puzzle’, in the interest of keeping the structure intact, to be fascinating. In her free time, Noelle enjoys pursuing her passions for interior design, photography, painting, jewelry design, and fiber arts.
                        </p>

                        <p>
                            Always an eclectic designer, Noelle enjoys the combination of old and new in juxtaposition and happily forages for vintage pieces to reside with the newest technology. She will work within clients budget including 'use what you have' interiors.
                        </p>
                        
                        <br/>

                        <h1 style={{font: 'Oswald'}} >Services Provided</h1>

                        <p className=''>
                            3D Rendering, Architectural / Structural Drawings using AutoCAD, Revit, and Vectorworks
                        </p>

                        <p className=''>
                            Plan preparation, specifications and necessary documentation to file plans with the NYC Department of Buildings NYC Department of Transportation, Manhattan Transit Authority and Landmarks Preservation Committee.
                        </p>

                        <p className=''>
                            Surveying and drawing of existing buildings (roof plans, plot plans, interiors, elevations and details) using a combination of on-site measurement, photography and satellite imagery.
                        </p>

                        <p className=''>
                            Residential and Corporate Interior Design & Space Planning
                        </p>

                </div>


            </div>

        )
    }

}

export default HomePage;