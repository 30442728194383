import React, { Component } from 'react';

import InfoCard from './InfoCard';
import ImagePreview from './ImagePreview';

import '../../styles/Gallery.css';

export default class Gallery extends Component {


    state = {

        imageViewer: null

    }


    viewImage = (src) => {

        if (!this.props.animate)
            return;

        console.log("Viewing ", src);

        this.setState({ imageViewer: src });

    }

    closeImage = () => {
        this.setState({ imageViewer: null});
    }

    render = () => {

        return (
        
            <div className={'gallery' + (this.props.animate ? ' animatedGallery' : '')}> 

                {this.props.items.map(itemDetails =>
                    <InfoCard 
                        image={itemDetails.src} 
                        animate={this.props.animate}
                        viewImage = {this.viewImage}
                    >

                        {itemDetails.title &&
                            <h1> { itemDetails.title} </h1>
                        }

                        {itemDetails.info && 
                            itemDetails.info.map(infoP => <p> {infoP} </p>)
                        }

                    </InfoCard>
                )}

                {this.state.imageViewer &&
                    <ImagePreview 
                        src = {this.state.imageViewer}
                        closePreview = {this.closeImage}
                    />
                }
                


            </div>

            


        )
        
    }


}