import React, { Component } from 'react';

import Gallery from '../Helpers/Gallery';
import GalleryDescription from '../Helpers/GalleryDescription';

import '../../styles/GalleryPage.css';

import {interiorDesign} from '../../constants/displayedItems';


export default class InteriorDesignGallery extends Component {



    render = () => {

        return (
        
            <div className='galleryPage fadeQuick'> 

                <div style = {{height: 40}}/>


                {/* Description */}

                <GalleryDescription
                    unpadTop = {true}
                >

                    <p>
                        Eclectic Interior Design.
                    </p>

                    <p className='indentText'>
                        - As a graduate of the Fashion Institute of Technology, Noelle has designed corporate offices, as well as commercial and residential spaces.
                    </p>

                </GalleryDescription>


                {/* Interior Design Gallery */}
                <Gallery
                    items = { interiorDesign }
                    animate = {true}
                />
                

            </div>

            


        )
        
    }


}