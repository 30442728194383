import React, { Component } from 'react';

import '../../styles/InfoCard.css';

export default class BioCard extends Component {

    viewImage = () => {

        // Pass image to parent for viewing
        if (this.props.animate)
            this.props.viewImage(this.props.image)

    }


    render = () => {

        return (
        
            <div className='bioContainer'>

                {/* Image */}
                <div className={'bioImgContainer' + (this.props.animate ? ' animatedBioImgContainer' : '')}>
                    <img className='bioImg' src={this.props.image} onClick={this.viewImage} />
                </div>
                
                {/* Text */}
                <div className='bioText'>

                    {this.props.children}

                </div>
            </div>

        )
        
    }


}