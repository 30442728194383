import React, { Component } from 'react';

import Gallery from '../Helpers/Gallery';
import GalleryDescription from '../Helpers/GalleryDescription';

import '../../styles/GalleryPage.css';


const contactItems = [

    {
        src: '/resources/Contact/Contact.jpeg',
        title: 'Contact',
        info: [
            'For information regarding:',
            '- Pricing for surveys and CAD work, ',
            '- Artwork & photography',
            ' or to see additional portfolio items and /or resume  information email: noelle@nfdesignstudio.com'

        ]

    }


]


export default class Contact extends Component {



    render = () => {

        return (
        
            <div className='galleryPage fadeQuick'> 

                <div style = {{height: 40}}/>


                {/* Description */}

                <GalleryDescription>

                </GalleryDescription>


                {/* Contact Gallery */}
                <Gallery
                    items = { contactItems }
                    animate = {false}
                />

                <br/> <br/>
                

            </div>

            


        )
        
    }


}