import React, { Component } from 'react';

import InfoCard from './InfoCard';

import '../../styles/Gallery.css';

export default class ImagePreview extends Component {



    render = () => {

        return (
        
            <div className={'imagePreview fadeQuick'}
                 onClick = {this.props.closePreview}
            > 


                {/* Display image */}
                <img 
                    src = {this.props.src}
                />


                {/* Close Button */}


            </div>

            


        )
        
    }


}